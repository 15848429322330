<template>
<div>
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading">
      <b-col>
        <b-card class="mb-3">
          <b-button class="mr-2" variant="outline-primary" v-on:click="getXls">download</b-button>
          <b-button v-if="permissions.add" variant="outline-primary" :to="{ name: 'ExtsourceAdd' }">add external source</b-button>

        </b-card>
        <b-card class="mb-3" v-for="row in rows" :key="row.id">
          <extsource
            :row="row"
            :username="user.username"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import zipcelx from 'zipcelx'

import ac from '../libs/accesscontrol'

import Extsource from '@/components/Extsource.vue'

export default {
  name: 'Extsources',
  beforeDestroy () {},
  components: {
    Extsource
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'external sources', action: 'open external sources' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('extsource').granted
    this.loadRisks()
  },
  data () {
    return {
      permissions: {
        add: false
      },
      buttonvariant: 'primary',
      loading: true,
      rows: ''
    }
  },
  methods: {
    getXls: function () {
      let data = []
      const headings = [
        { value: 'RiskId', type: 'string' },
        { value: 'Name', type: 'string' },
        { value: 'Coments', type: 'string' },
        { value: 'Link', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.rows.length; i < len; i++) {
        let row = [
          { value: this.rows[i].id, type: 'string' },
          { value: this.rows[i].name, type: 'string' },
          { value: this.rows[i].comments, type: 'string' },
          { value: this.rows[i].link, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'extsources', sheet: { data: data } }
      zipcelx(config)
    },
    loadRisks: async function () {
      this.$logger.debug('loading started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/extsources'
        let response = await this.$Amplify.API.get(apiName, path)
        this.rows = _.sortBy(response, 'name')
        this.loading = false
        this.$logger.debug(this.risk)
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
    }
  },
  mounted () {},
  watch: {}
}
</script>

<style>
</style>
